
<template>
  <div class="sidebar-nav-container">
    <div class="sidebar">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
        <span class="logo-text">Foadmin</span>
      </div>
      <ul class="nav-list">
        <li v-for="item in menuItems" :key="item.name" :class="{ active: isActive(item.path) }">
          <router-link :to="item.path" @click="setActiveMenu(item)">
            <component :is="item.icon" class="icon" />
            <span>{{ item.name }}</span>
          </router-link>
          <div class="vertical-line" v-if="isActive(item.path)"></div>
        </li>
      </ul>
    </div>
    <div class="secondary-menu" v-if="hasSecondaryMenu(activeMenu)">
      <ul class="nav-list">
        <li v-for="subItem in activeMenu.secondaryMenu" :key="subItem.name" :class="{ active: isActive(subItem.path) }">
          <router-link :to="subItem.path">
            <span>{{ subItem.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  Stopwatch,
  Document,
  Picture,
  User,
  ChatLineRound,
  BrushFilled,
  Box,
  Shop,
  View,
  Setting,
  ShoppingTrolley
} from '@element-plus/icons-vue';

export default {
  name: 'SidebarNav',
  components: {
    Stopwatch,
    Document,
    Picture,
    User,
    ChatLineRound,
    BrushFilled,
    Box,
    Shop,
    View,
    Setting,
    ShoppingTrolley
  },
  data() {
    return {
      menuItems: [
        { name: '仪表盘', path: '/dashboard', icon: Stopwatch },
        { name: '权限', path: '/permissions', icon: View, secondaryMenu: [
            { name: '管理列表', path: '/permissions/adminroles' },
            { name: '角色管理', path: '/permissions/roles' },
            { name: '权限管理', path: '/permissions/ruleroles' }
          ]
        },

        { name: '媒体', path: '/mediaLibrary', icon: Picture },
        { name: 'SSL管理', path: '/orders', icon: ShoppingTrolley, secondaryMenu: [
             { name: '产品列表', path: '/orders/ProDucts' },
            { name: '订单列表', path: '/orders/list' },
            
          ]
        },

        { name: '员工', path: '/users', icon: User, secondaryMenu: [
            { name: '员工列表', path: '/users/list' },
            
          ]
        },

        { name: '外观', path: '/Theme', icon: BrushFilled, secondaryMenu: [
            { name: '模板', path: '/Theme/Theme' },
            
          ] },
      
        { name: '设置', path: '/Setting', icon: Setting, secondaryMenu: [
            { name: '常规', path: '/Setting/general' },
            
          ] },
        
      ],
      activeMenu: null
    };
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.updateActiveMenu();
      }
    }
  },
  methods: {
    isActive(path) {
      return this.$route.path.startsWith(path);
    },
    setActiveMenu(menu) {
      this.activeMenu = menu;
      if (this.hasSecondaryMenu(menu)) {
        this.$router.push(menu.secondaryMenu[0].path);
      }
    },
    updateActiveMenu() {
      this.activeMenu = this.menuItems.find(item => this.isActive(item.path));
    },
    hasSecondaryMenu(menu) {
      return menu && menu.secondaryMenu && menu.secondaryMenu.length > 0;
    }
  }
};
</script>

<style scoped>
.sidebar-nav-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 160px;
  background-color: #22242f;
  color: #919297;
}

.secondary-menu {
  width: 120px;
  background-color: #FFFFFF;
  color: #919297;
  border-right: 1px solid #e0e0e0;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.logo img {
  max-width: 30px;
  height: 30px;
  margin-right: 30px;
}

.logo-text {
  font-size: 20px;
  font-weight: 100;
  color: white;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-list li {
  position: relative;
  padding: 2px 10px;
  font-size: 14px;
}

.nav-list li a {
  color: #919297;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  transition: color 0.3s;
}

.nav-list li a:hover {
  color: #ffffff;
}

.nav-list li.active a {
  color: #ffffff;
}

.nav-list li .icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  font-size: 14px;
}

.nav-list li .vertical-line {
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: calc(100% - 20px);
  background-color: #ffffff;
  border-radius: 3px;
}

.secondary-menu .nav-list li a {
  color: #919297;
  transition: color 0.3s;
}

.secondary-menu .nav-list li a:hover {
  color: #22242f;
}

.secondary-menu .nav-list li.active a {
  color: #000000;
  font-weight: bold;
}
</style>





