<template>
    <div>
      <el-button color="#626aef" type="primary" @click="openAddDrawer">添加员工</el-button>
      <el-button type="danger" @click="confirmBatchDelete" :disabled="multipleSelection.length === 0">批量删除</el-button>
      <div class="table-wrapper">
        <el-table
          ref="multipleTable"
          :data="admins"
          style="width: 100%; margin-top: 20px;"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="ID" width="50" />
          <el-table-column prop="username" label="员工名" />
          <el-table-column prop="nickname" label="昵称" />
          <el-table-column prop="email" label="邮箱" />
          <el-table-column prop="phone" label="手机号" />
          <el-table-column label="角色">
            <template #default="scope">
              <span>{{ scope.row.role ? scope.row.role.name : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="头像">
            <template #default="scope">
              <img :src="scope.row.avatar || defaultAvatar" alt="头像" style="width: 30px; height: 30px; border-radius: 50%;" />
            </template>
          </el-table-column>
          <!--<el-table-column prop="description" label="简介" />-->
          <el-table-column label="状态">
            <template #default="scope">
              <span>{{ scope.row.state === 0 ? '正常' : '禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="logintime" label="最后登陆" />
          <el-table-column prop="ip" label="登陆IP" />
          <el-table-column label="操作">
            <template #default="scope">
                <div class="action-buttons">
              <el-button size="mini" @click="openEditDrawer(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="confirmDelete(scope.row.id)">删除</el-button>
                </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="custom-pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pagination.total"
          @current-change="handlePageChange"
          :page-size="pagination.pageSize"
          :current-page="pagination.page"
          style="margin-top: 20px; justify-content: center;"
        />
      </div>
      <!-- 添加员工抽屉 -->
       <el-drawer v-model="addDrawerVisible" title="添加员工" direction="rtl" size="30%">
        <template #default>
          <el-form :model="form">
            <el-form-item label="员工名">
              <el-input v-model="form.username" />
            </el-form-item>
            <el-form-item label="---昵称">
              <el-input v-model="form.nickname" />
            </el-form-item>
            <el-form-item label="---密码">
              <el-input type="password" v-model="form.password" />
            </el-form-item>
            <el-form-item label="---邮箱">
              <el-input v-model="form.email" />
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="form.phone" />
            </el-form-item>
            <el-form-item label="---角色">
              <el-select v-model="form.role_id" placeholder="选择角色">
                <el-option
                  v-for="role in roles"
                  :key="role.id"
                  :label="role.name"
                  :value="role.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="---头像">
              <el-input v-model="form.avatar" placeholder="头像 URL" />
              <el-button @click="showMediaLibrary" class="image-preview">选择头像</el-button>
              <div class="image-preview">
                <img :src="form.avatar || defaultAvatar" alt="头像预览" />
              </div>
            </el-form-item>
            <el-form-item label="简介">
              <el-input v-model="form.description" />
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="form.state" placeholder="选择状态">
                <el-option label="正常" value="0"></el-option>
                <el-option label="禁用" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
        <template #footer>
          <div class="drawer-footer">
            <el-button color="#626aef" @click="addDrawerVisible = false" plain>取消</el-button>
            <el-button color="#626aef" type="primary" @click="handleAdd">确定</el-button>
          </div>
        </template>
      </el-drawer>
  
      <!-- 编辑员工抽屉 -->
      <el-drawer v-model="editDrawerVisible" title="编辑员工" direction="rtl" size="30%">
        <template #default>
          <el-form :model="form">
            <el-form-item label="员工名">
              <el-input v-model="form.username" />
            </el-form-item>
            <el-form-item label="---昵称">
              <el-input v-model="form.nickname" />
            </el-form-item>
            <el-form-item label="---邮箱">
              <el-input v-model="form.email" />
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="form.phone" />
            </el-form-item>
            <el-form-item label="---角色">
              <el-select v-model="form.role_id" placeholder="选择角色">
                <el-option
                  v-for="role in roles"
                  :key="role.id"
                  :label="role.name"
                  :value="role.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="---头像">
              <el-input v-model="form.avatar" placeholder="头像 URL" />
              <el-button @click="showMediaLibrary" class="image-preview">选择头像</el-button>
              <div class="image-preview">
                <img :src="form.avatar || defaultAvatar" alt="头像预览" />
              </div>
            </el-form-item>
            <el-form-item label="简介">
              <el-input v-model="form.description" />
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="form.state" placeholder="选择状态">
                <el-option label="正常" value="0"></el-option>
                <el-option label="禁用" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="密码">
              <el-input type="password" v-model="form.new_password" placeholder="不填写则不更改" />
            </el-form-item>
          </el-form>
        </template>
        <template #footer>
          <div class="drawer-footer">
            <el-button color="#626aef" @click="editDrawerVisible = false" plain>取消</el-button>
            <el-button color="#626aef" type="primary" @click="handleEdit">确定</el-button>
          </div>
        </template>
      </el-drawer>
  
      <!-- 媒体库对话框 -->
      <el-dialog title="选择媒体" v-model="mediaDialogVisible">
        <media-library :selectMode="true" @media-selected="handleMediaSelected" />
      </el-dialog>
  
      <!-- Custom Alert -->
      <CustomAlert v-if="alert.visible" :type="alert.type" :message="alert.message" @close="alert.visible = false" />
  
      <!-- Confirm Dialog -->
      <ConfirmDialog
        v-model:visible="confirmDialogVisible"
        message="确定要删除这个员工吗？"
        @confirm="handleDelete"
        @cancel="confirmDialogVisible = false"
      />
  
      <!-- Confirm Batch Delete Dialog -->
      <ConfirmDialog
        v-model:visible="confirmBatchDialogVisible"
        message="确定要删除选中的员工吗？"
        @confirm="handleBatchDelete"
        @cancel="confirmBatchDialogVisible = false"
      />
    </div>
  </template>
  

<script>
import api from '@/api'; // 引入已配置好的api实例
import CustomAlert from '@/components/CustomAlert.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import MediaLibrary from '@/views/MediaLibrary.vue'; // 确保这个路径是正确的
import defaultAvatar from '@/assets/default-avatar.png'; // 引入默认头像

export default {
    name: 'AdminRoles',
    components: {
        CustomAlert,
        ConfirmDialog,
        MediaLibrary
    },
    data() {
        return {
            admins: [],
            roles: [],
            form: {
                id: '',
                username: '',
                nickname: '',
                email: '',
                phone: '',
                role_id: '',
                avatar: '',
                description: '',
                state: 0,
                new_password: ''
            },
            originalForm: {},
            addDrawerVisible: false,
            editDrawerVisible: false,
            mediaDialogVisible: false,
            confirmDialogVisible: false,
            confirmBatchDialogVisible: false,
            currentDeleteId: null,
            multipleSelection: [],
            alert: {
                visible: false,
                type: '',
                message: '',
            },
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            defaultAvatar // 使用默认头像
        };
    },
    created() {
        this.fetchUsers();
        this.fetchRoles();
    },
    methods: {
        fetchUsers() {
            api.get('/api/userslist', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                params: {
                    page: this.pagination.page,
                    pageSize: this.pagination.pageSize,
                }
            }).then(response => {
                const { data, total } = response.data;
                this.admins = data;
                this.pagination.total = total;
            }).catch(error => {
                console.error('获取员工列表失败', error);
                this.showAlert('error', '获取员工列表失败');
            });
        },
        fetchRoles() {
            api.get('/api/roleslistt', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
                this.roles = response.data.data;
            }).catch(error => {
                console.error('获取角色列表失败', error);
                this.showAlert('error', '获取角色列表失败');
            });
        },
        handlePageChange(newPage) {
            this.pagination.page = newPage;
            this.fetchUsers();
        },
        showMediaLibrary() {
            this.mediaDialogVisible = true;
        },
        handleMediaSelected(media) {
            this.form.avatar = media.filepath;
            this.mediaDialogVisible = false;
        },
        openAddDrawer() {
            this.form = {
                id: '',
                username: '',
                nickname: '',
                email: '',
                phone: '',
                role_id: '',
                avatar: '',
                description: '',
                state: 0,
                new_password: ''
            };
            this.addDrawerVisible = true;
        },
        handleAdd() {
            api.post('/api/userslist', this.form, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(() => {
                this.addDrawerVisible = false;
                this.fetchUsers();
                this.showAlert('success', '添加员工成功');
            }).catch(error => {
                console.error('添加员工失败', error);
                this.showAlert('error', '添加员工失败');
            });
        },
        openEditDrawer(admin) {
            this.form = { ...admin, new_password: '' }; // 添加 new_password 字段
            this.originalForm = { ...admin }; // 保存原始表单数据
            this.editDrawerVisible = true;
        },
        handleEdit() {
            const payload = {};
            // 比较表单数据与原始数据，仅提交已修改的字段
            for (const key in this.form) {
                if (this.form[key] !== this.originalForm[key] && key !== 'new_password') {
                    payload[key] = this.form[key];
                }
            }
            // 如果 new_password 不为空，则包含在请求体中
            if (this.form.new_password) {
                payload.password = this.form.new_password;
            }

            // 检查员工名和邮箱是否更改，如果更改则包含在请求体中
            if (this.form.username !== this.originalForm.username) {
                payload.username = this.form.username;
            }
            if (this.form.email !== this.originalForm.email) {
                payload.email = this.form.email;
            }

            api.put(`/api/userslist/${this.form.id}`, payload, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(() => {
                this.editDrawerVisible = false;
                this.fetchUsers();
                this.showAlert('success', '编辑员工成功');
            }).catch(error => {
                console.error('编辑员工失败', error);
                this.showAlert('error', '编辑员工失败');
            });
        },
        confirmDelete(id) {
            this.currentDeleteId = id;
            this.confirmDialogVisible = true;
        },
        handleDelete() {
            if (this.currentDeleteId) {
                api.delete(`/api/userslist/${this.currentDeleteId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(() => {
                    this.fetchUsers();
                    this.showAlert('success', '删除员工成功');
                }).catch(error => {
                    console.error('删除员工失败', error);
                    this.showAlert('error', '删除员工失败');
                }).finally(() => {
                    this.confirmDialogVisible = false;
                    this.currentDeleteId = null;
                });
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        confirmBatchDelete() {
            this.confirmBatchDialogVisible = true;
        },
        handleBatchDelete() {
            const ids = this.multipleSelection.map(item => item.id);
            api.post('/api/userslistt/userbatch-delete', { ids }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(() => {
                this.fetchUsers();
                this.showAlert('success', '批量删除成功');
            }).catch(error => {
                console.error('批量删除失败', error);
                this.showAlert('error', '批量删除失败');
            }).finally(() => {
                this.confirmBatchDialogVisible = false;
                this.multipleSelection = [];
            });
        },
        showAlert(type, message) {
            this.alert = {
                visible: true,
                type,
                message,
            };
        },
    },
    watch: {
        editDrawerVisible(val) {
            if (val && this.form.id) {
                this.form.state = this.form.state.toString();
            }
        }
    }
};
</script>

<style scoped>
.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-item label {
  width: 100px; /* 根据最长的文字宽度调整 */
  text-align: right;
  padding-right: 10px;
}

.form-item input,
.form-item select {
  flex: 1;
}







.action-buttons {
  display: flex;
  gap: 5px; /* Adjust the gap as needed */
  white-space: nowrap; /* Prevent line break */
}


.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.image-preview {
  margin-top: 10px;
}
.image-preview img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #f9f9f9;
}

.custom-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.el-pagination {
  background-color: #ffffff00; /* 自定义背景色 */
  padding: 10px;
  border-radius: 5px;
}

.el-pagination .el-pager li:not(.is-active) {
  background-color: #ffffff; /* 设置非激活状态分页项的背景色 */
}

.custom-pagination >>> .el-pager .number.is-active {
  background-color: #626aef !important; /* 使用 !important 确保样式优先级 */
}
</style>
