import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import MainLayout from '@/views/MainLayout.vue';
import Orders from '@/views/orders/Orders.vue';
import Users from '@/views/users/Users.vue';
import MediaLibrary from '@/views/MediaLibrary.vue';
import Profile from '@/views/Profile.vue';
import CategoryManager from '@/views/CategoryManager.vue';
import Roles from '@/views/Roles.vue';
import AdminRoles from '@/views/AdminRoles.vue';
import RuleRoles from '@/views/RuleRoles.vue';
import Write from '@/views/article/Write.vue';
import ArticleTags from '@/views/article/ArticleTags.vue';
import ArticlePost from '@/views/article/ArticlePost.vue';
import EditWrite from '@/views/article/EditWrite.vue';
import ArticleDraft from '@/views/article/ArticleDraft.vue';
import Settinggeneral from '@/views/setting/General.vue';
import ThemeAdmin from '@/views/theme/Theme.vue';
import ThemeSettings from '@/views/theme/ThemeSettings.vue';
import NavBar from '@/views/theme/NavBar.vue';
import PaGe from '@/views/theme/PaGe.vue';
import PaGePost from '@/views/theme/PaGePost.vue';
import EditWriteT from '@/views/theme/EditWrite.vue';
import LinK from '@/views/theme/Link.vue';
import ProDucts from '@/views/ProDucts.vue';
const routes = [
    { path: '/login', component: Login },
    {
        path: '/',
        component: MainLayout,
        children: [
            { path: '', redirect: 'dashboard' },
            { path: '/dashboard', component: Dashboard, meta: { title: '仪表盘' } },
            { path: '/users/list', component: Users, meta: { title: '用户列表' } },
            { path: '/orders/list', component: Orders, meta: { title: '订单列表' } },
            { path: '/mediaLibrary', component: MediaLibrary, meta: { title: '媒体库' } },
            { path: '/profile', component: Profile, meta: { title: '个人资料' } },
            { path: '/article/categoryManager', component: CategoryManager, meta: { title: '分类管理' } },
            { path: '/orders/ProDucts', component: ProDucts, meta: { title: '产品管理' } },
            { path: '/permissions/roles', component: Roles, meta: { title: '角色管理' } },
            { path: '/permissions/adminroles', component: AdminRoles, meta: { title: '管理员列表' } },
            { path: '/permissions/ruleroles', component: RuleRoles, meta: { title: '权限管理' } },
            { path: '/article/ArticlePost', component: ArticlePost, meta: { title: '所有文章' } },
            { path: '/article/write', component: Write, meta: { title: '写文章' } },
            { path: '/article/EditWrite', component: EditWrite, meta: { title: '编辑文章' } },
            { path: '/article/ArticleDraft', component: ArticleDraft, meta: { title: '草稿' } },
            { path: '/article/ArticleTags', component: ArticleTags, meta: { title: '标签管理' } },
            { path: '/Setting/general', component: Settinggeneral, meta: { title: '常规设置' } },
            { path: '/Theme/Theme', component: ThemeAdmin, meta: { title: '模板管理' } },
            { path: '/Theme/themes/:id/settings', name: 'ThemeSettings', component: ThemeSettings, meta: { title: '模板设置' } },
            { path: '/Theme/NavBar', name: 'NavBar', component: NavBar, meta: { title: '导航管理' } },
            { path: '/Theme/PaGe', name: 'PaGe', component: PaGe, meta: { title: '添加单页' } },
            { path: '/Theme/PaGePost', name: 'PaGePost', component: PaGePost, meta: { title: '单页管理' } },
            { path: '/Theme/EditWrite', component: EditWriteT, meta: { title: '编辑单页' } },
            { path: '/Theme/LinK', component: LinK, meta: { title: '链接管理' } },




        ],
    },
    { path: '/:catchAll(.*)', redirect: '/dashboard' },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    console.log('Navigating to:', to.path);
    console.log('Token:', token);
    if (to.path !== '/login' && !token) {
        next('/login');
    } else {
        next();
    }
});

export default router;